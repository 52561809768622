@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap");
.product__container {
  /* background-color: black; */
  background-image: var(--gradient),
    url("../../Images/Bike-phone-bg1.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  margin: 0;
}
.product__heading {
  font-family: "Oswald", sans-serif;
  width: fit-content;
  margin: auto;
  display: flex;
  text-align: center;
  padding: 10px;
  padding-bottom: 0;
  font-size: 2.8rem;
}
.product__heading__first {
  color: var(--thirdColor);
}

.product__heading__second {
  border-bottom: 6px solid var(--thirdColor);
}
.product__list__container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 900px) {
  .product__container {
    /* background-color: black; */
    background-image: var(--gradient),
      url("../../Images/Bike-bg3.jpeg");
  }
}
