body {
  background-color: rgb(0, 30, 69) !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --gradient: linear-gradient(
        rgba(0, 30, 69, 1),
        rgba(0, 30, 69, 0.7),
        rgba(0, 30, 69, 0.7),
        rgba(0, 30, 69, 1)
      );
  --bgAccent: rgb(0, 30, 69);
  --cardBg: rgb(0, 21, 47);
  --thirdColor: rgb(135, 255, 251);
}
