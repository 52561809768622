@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');
.deals__container{
    color: white;
    background-image: linear-gradient( var(--bgAccent),
                       rgb(88, 88, 88),rgb(88, 88, 88),var(--bgAccent));
}
.deals__heading{
    font-family: 'Oswald', sans-serif;
    width: fit-content;
    margin: auto;
    display: flex;
    text-align: center;
    padding: 10px;
    padding-bottom: 0;
    font-size: 2.8rem;
}
.deals__heading__first{
    color: var(--thirdColor);
    margin-right: 10px;
}

.deals__heading__second{
    border-bottom: 6px solid var(--thirdColor);
}

