.navBar__container {
  position: fixed;
  left: 5%;
  bottom: 5%;
  z-index: 100;
  width: 90vw;
  display: flex;
  align-items: center ;
  justify-content: space-between ;
  padding: 5px 20px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(8px);
  height: 60px;
  /* overflow-y: hidden ; */
}


.navBar__icon {
  display: flex;
  text-decoration: none ;
  color: white;
  background-color: var(--cardBg);
  font-size: 2.6rem ;
  border: 2px solid var(--cardBg);
  border-radius: 50px;
  padding: 5px ;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}

.navBar__link:hover .navBar__icon {
  background-color: rgb(29, 29, 29);
  border: 2px solid var(--thirdColor);
  color: var(--thirdColor) ;
  /* transform: translateY(-15px);
  transition: transform 0.4s ease; */
}
.navBar__text{
  color: black;
  font-weight: 500;
  position: fixed;
  top: 32px;
  opacity: 0;
  font-size: 0.9rem;
}
.navBar__link:hover .navBar__text{
  /* opacity: 1; */
}
a:link{
  text-decoration: none;
}
.navBar__link{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.navBar__link.active .navBar__icon{
    background-color: rgb(29, 29, 29);
  border: 2px solid var(--thirdColor);
  color: var(--thirdColor) ;
  transform: translateY(-15px);
  transition: transform 0.4s ease;
}

a.active .navBar__text{
    opacity: 1;
}
@media screen and (min-width: 990px) {
  .navBar__container {
    left: 30%;
    width: 40vw;
  }
  .navBar__text{
  font-size: 0.97rem;
}
}
